/* Login */
.login__background {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/bgbg.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  z-index: 1031;
}

.login__container {
  width: 37%;
  background-color: var(--white);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.app__logo {
  text-align: center;
}
.app__logo img {
  width: 200px;
  height: 100px;
}
.login__form h1 {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: var(--primary-color);
}

.login__form h2 {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: var(--primary-color);
}

.login_button {
  text-align: center;
}

.login__form button,
.login__form button:hover {
  background-color: var(--primary-color);
  margin: 10px 0;
  font-weight: 500;
  font-size: 1.2rem;
  max-width: 390px;
  border-radius: 10px;
  width: 100%;
  padding: 5px 88px;
  color: var(--white);
}

.login__form .input-group {
  box-shadow: 0 0 10px rgb(179, 179, 179);
  margin: 0.8rem 0;
  padding: 15px;
}
.login__form .input-group span {
  border-right: 1px solid #d4d2d2;
}
.login__form .registration__link {
  display: flex;
  justify-content: center;
  color: var(--primary-color);
}

.login__form .input-group input {
  width: 300px;
}

.registration__link span {
  font-size: 13px;
  text-align: center;
  color: black;
}

.firebaseui-idp-list > .firebaseui-list-item button {
  background-color: var(--primary-color) !important;
}
.firebaseui-idp-google > .firebaseui-idp-text {
  color: #fff !important;
}

.forgot-password {
  cursor: pointer;
  font-size: 14px;
}

.forgot-password:hover {
  color: cornflowerblue;
  text-decoration: underline;
}

@media only screen and (max-width: 800px) {
  .login__container {
    width: 100%;
  }
}
