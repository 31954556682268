.edit__profile_container .edit__profile_title {
  margin-bottom: 1rem;
}
.edit__profile_container .edit__profile_title h3 {
  margin-bottom: 1rem;
  font-weight: bolder;
}

.edit__profile_container h4 {
  font-weight: bold;
  color: var(--primary-color);
}
.edit__profile_container img {
  border-radius: 150px;
  width: 15rem;
  height: 15rem;
}
.edit__profile_container .image-upload {
  font-size: 2rem;
  margin-left: 12rem;
  margin-bottom: -2rem;
}

.edit__profile_container .image-upload label{ 
  cursor: pointer;
}

.edit__profile_container .image-upload > input {
  display: none;
}
