@font-face {
  font-family: "VAG";
  src: url("./fonts/VAG.ttf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "VAG", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

/* To change the whole app color */
:root {
  --primary-color: #009ee0;
  --secondary-color: #ffcc00;
  --primary-text-color: #37404f;
  --secondary-text-color: #ffffff;
  --yellow: #e7af1e;
  --white: #ffffff;
  --orange: #ff3b0f;
  --green: #00b678;
  --blue: #5458e1;
  --purple: #00bbdd;
  --error: #ff4f6c;
  --black: #343a40;
}
body {
  background: #e8edf5;
  width: 100%;
  height: 100%;
}
.fs-20 {
  font-size: 25px;
}
.fs-10 {
  font-size: 13px;
}
.error-message {
  color: #cc4949;
  font-size: 12px;
}

._1ceqH ._dt3-T {
  min-height: 450px !important;
  background: #212121;
  border-radius: 0 0 3px 3px;
  display: flex;
  flex-direction: column;
}

.primary-button {
  background-color: var(--primary-color);
  color: #fff;
}

.primary-button:hover {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
}

.primary-button-outline {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.primary-button-outline:hover {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
  border: 2px solid var(--secondary-color);;
}

.pointer {
  cursor: pointer;
}

.floating-button {
  position: fixed;
  bottom: 70px;
  right: 70px;
  box-shadow: 0 0 5px #cecece;
}

.loading-color {
  color: var(--black);
}

.vairation-button {
  background-color: var(--yellow);
  padding: 5px 70px;
  display: block;
  font-weight: bold;
  color: var(--white);
}

@media only screen and (max-width: 700px) {
  .floating-button {
    position: fixed;
    bottom: 50px;
    right: 20px;
  }
}

/* Input element style */
input {
  border: none;
  background-color: transparent;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.input-group {
  padding: 20px 10px;
  background-color: var(--white);
  border-radius: 10px;
  color: #000;
  width: auto;
  display: flex;
}

.input-group span {
  color: var(--primary-color);
  width: 50px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-group-inner {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.input-group-inner label {
  padding: 0;
  margin: 0;
  font-size: 17px;
}

.input-group-inner input {
  padding: 0;
  margin: 0;
  font-size: 17px;
}

.advertisement img {
  width: 100%;
  height: 300px;
  object-fit: fill;
}

/* Table style */
.responsive__table {
  width: 100%;
  border-collapse: collapse;
}

.responsive__table thead tr:first-child {
  border-radius: 10px;
  margin-left: 10px;
}

.responsive__table tbody tr {
  margin-bottom: 5px;
}
.responsive__table td,
.responsive__table th {
  padding: 17px 10px;
  text-align: start;
  font-size: 16px;
}
.responsive__table td:first-child:last-child {
  padding-top: 25px;
}

.table-list-item:not(:last-child) {
  font-size: 14px;
  border-bottom: 1px solid #aaa9a9;
  margin: 1px 0;
}

@media only screen and (max-width: 600px) {
  /* Table Responsiveness */
  .responsive__table thead {
    display: none;
  }

  .responsive__table,
  .responsive__table tbody,
  .responsive__table tr,
  .responsive__table td {
    display: block;
    width: 100%;
  }
  .responsive__table tr {
    margin-bottom: 15px;
    border: 1px solid gray;
  }
  .responsive__table td {
    text-align: right;
    padding-left: 50%;
    text-align: right;
    position: relative;
  }
  .responsive__table th {
    padding: 5px 15px;
  }
  .responsive__table td {
    padding-bottom: 5px;
  }
  .responsive__table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
  .table-list-item {
    padding: 5px 0;
  }
  .table-list-item:not(:last-child) {
    margin: 5px 0;
  }
}
